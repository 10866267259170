// src/pages/Home.js
import React, { useEffect, useState, useRef, useMemo    } from "react";
import {  Dialog, 
          DialogTitle, 
          DialogContent, 
          DialogActions, 
          Grid,Accordion, 
          AccordionSummary, 
          AccordionDetails, 
          Paper, 
          Alert, 
          Snackbar, 
          LinearProgress, 
          FormGroup, 
          FormControlLabel, 
          Checkbox, 
          CssBaseline, 
          Container, 
          Box, 
          TextField, 
          Button, 
          Typography, 
          Avatar, 
          Tooltip, 
          IconButton, 
          List, 
          ListItem, 
          ListItemText,
          ListItemAvatar,
          Table ,
          TableBody ,
          TableCell ,
          TableContainer ,
          TableHead ,
          TableRow} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { auth, db, query, collection, limit, getDocs } from "../firebaseConfig";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { useUser } from '../UserContext';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { updateDoc, doc, getDoc, setDoc, where  } from "firebase/firestore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from "@mui/system";
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import axios from "axios";
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import CheckIcon from "@mui/icons-material/Check";
import MuiAlert from "@mui/material/Alert";

const FireButton = styled(Button)(({ theme }) => ({
  margin: "20px",
  padding: 0,
  borderRadius: "10px",
  overflow: "hidden",
  transition: "0.3s",
  "&:hover": {
    border: "2px solid",
    borderImageSource: "linear-gradient(45deg, red, orange, yellow)",
    borderImageSlice: 1,
    boxShadow: "0 0 10px rgba(255, 69, 0, 0.8), 0 0 20px rgba(255, 140, 0, 0.6)",
    transform: "scale(1.05)",
  },
}));




const BagwisProfitableAdmin  = () => {

   const { user, logout } = useUser();

   const [userCount, setUserCount] = useState(0);
   const userDocumentFetched = useRef(false);
   const [searchEmail, setSearchEmail] = useState("");
   const [loading, setLoading] = useState(true);
   const [render, setRender] = useState(false);
   const [userData, setUserData] = useState(null);
   const [users, setUsers] = useState([]);
   const [error, setError] = useState("");
   const [editable, setEditable] = useState(null); // Tracks the editable user ID
   const [updatedLotsCredits, setUpdatedLotsCredits] = useState({}); // Tracks new values for lotsCredits
   const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar visibility
   const [snackbarMessage, setSnackbarMessage] = useState(""); // Snackbar message
   const navigate = useNavigate();

   

    useEffect(() => {
         
          
        if( typeof user !== 'undefined' &&  user !== null )
        {
            if( user.authChecked === true && typeof user.uid === 'undefined' ||  user.uid === null  )
            {
              navigate("/signIn");
            }else if( user.authChecked === true && typeof user.uid !== 'undefined'  )
            {     
                if (!userDocumentFetched.current)
                {
                    const fetchUserDocument = async () => {

                        const userDocRef = doc(db, "users", user.uid);
                        const userDocSnap = await getDoc(userDocRef);

                        if (userDocSnap.exists())
                        {

                           let user_ = userDocSnap.data();
                     
                           if( typeof user_.bagwisadmin !== 'undefined' &&  user_.bagwisadmin !== null && user_.bagwisadmin ==1 )
                           {

                           }else{
                              setRender(false);
                              navigate("/");
                           }
                        }
                        else
                        {
                          console.log("No such document!");
                        }

                        setRender(true);
                        setLoading(false);

                    };

                    const fetchAdminSettings = async () =>{

                       const adminSettingsCollection = collection(db, "adminSettings");
                       const q = query(adminSettingsCollection, limit(1));

                       const querySnapshot = await getDocs(q);

                        if (!querySnapshot.empty) {
                          // Get the first (and only) document
                          querySnapshot.forEach((doc) => {
                            setUserCount(doc.data().usersCount)
                          });
                        } else {
                          console.log("No documents found in the adminSettings collection.");
                        }

                    }


                  fetchUserDocument();
                  fetchAdminSettings();
                }


            }
        }
       

    }, [user]);

    
  const handleSearch = async () => {
      if (!searchEmail.trim()) {
        setError("Please enter an email to search.");
        return;
      }

      setLoading(true);
      setError("");
      setUsers([]);

      try {
        const usersRef = collection(db, "users");
        const q = query(usersRef, where("email", "==", searchEmail));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          
          const userList = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setUsers(userList);

        } else {
          setError("No user found with this email.");
        }
      } catch (err) {
        console.error("Error searching for user:", err);
        setError("An error occurred while searching for the user.");
      } finally {
        setLoading(false);
    }
  };
    
  const handleLotsCreditsChange = (id, value) => {
  // Allow only valid decimal numbers
  if (/^\d*\.?\d*$/.test(value)) {
    setUpdatedLotsCredits((prev) => ({ ...prev, [id]: value }));
  }
};

   const handleEdit = (id) => {
    setEditable(id);
  };

  const handleSave = async (id) => {
    const newLotsCredits = updatedLotsCredits[id];
    if (newLotsCredits !== undefined) {
      try {
        const userDocRef = doc(db, "users", id);
        await updateDoc(userDocRef, { lotsCredits: Number(newLotsCredits) });
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === id ? { ...user, lotsCredits: newLotsCredits } : user
          )
        );
        setEditable(null);
        setUpdatedLotsCredits((prev) => ({ ...prev, [id]: undefined }));
        setSnackbarMessage("Lots Credits updated successfully!");
        setSnackbarOpen(true);
      } catch (err) {
        console.error("Error updating lotsCredits:", err);
      }
    }
  };
 
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
   
   return (
    <>
      <CssBaseline />
     
      { render && <Container maxWidth="md">
           { loading && <LinearProgress
                          sx={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: 8,
                            borderRadius: 4,
                            backgroundColor: "rgba(255, 69, 0, 0.2)", // Light fire red for the track
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#FF4500", // Fire red color for the progress bar
                            },
                          }}
                        />
          }
          <h1 style={{ fontSize: "3rem", textAlign: "center", margin:"10px" }}>All Users</h1>
        <Box 
           sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2,
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: 'rgba(0, 0, 0, 0.87)'
              }}
        >
          <h1 style={{ color:'#fff', fontSize: "3rem" }}>{userCount}</h1>
        </Box>

          <h1 style={{ fontSize: "3rem", textAlign: "center", margin:"10px" }}>Search User</h1>
           <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
              <TextField
                label="Search by Email"
                variant="outlined"
                fullWidth
                value={searchEmail}
                onChange={(e) => setSearchEmail(e.target.value)}
              />
              <Button variant="contained" onClick={handleSearch}>
                Search
              </Button>

          </Box>
          <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>

            {  users.length > 0 && (
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell><center>Avatar</center></TableCell>
                        <TableCell><center>Name</center></TableCell>
                        <TableCell><center>Email</center></TableCell>
                        <TableCell><center>Lots Credits</center></TableCell>
                        <TableCell><center>Max Position</center></TableCell>
                        <TableCell><center>Starting Lot Size</center></TableCell>
                        <TableCell><center>Trading Sessions</center></TableCell>
                        <TableCell><center>User Token</center></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((user) => (
                        <TableRow key={user.id}>
                          {/* Avatar */}
                          <TableCell>
                            <Avatar src={user.profilePic || undefined}>
                              {user.name?.[0]?.toUpperCase()}
                            </Avatar>
                          </TableCell>

                          {/* User Info */}
                          <TableCell><center>{user.name || "N/A"}</center></TableCell>
                          <TableCell><center>{user.email || "N/A"}</center></TableCell>
                          
                          <TableCell>
                            {editable === user.id ? (
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                <TextField
                                  type="text"
                                  value={
                                    updatedLotsCredits[user.id] !== undefined
                                      ? updatedLotsCredits[user.id]
                                      : user.lotsCredits
                                  }
                                  onChange={(e) =>
                                    handleLotsCreditsChange(user.id, e.target.value)
                                  }
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "70px", marginRight: 1 }}
                                />
                                <IconButton onClick={() => handleSave(user.id)}>
                                  <CheckIcon color="primary" />
                                </IconButton>
                              </Box>
                            ) : (
                              <Box onClick={() => handleEdit(user.id)}>
                                {user.lotsCredits || "N/A"}
                              </Box>
                            )}
                          </TableCell>

                          <TableCell><center>{user.maxPosition || "N/A"}</center></TableCell>
                          <TableCell><center>{user.startingLotSize || "N/A"}</center></TableCell>
                          <TableCell><center>{user.tradingSessions || "N/A"}</center></TableCell>
                          <TableCell><center>{user.userToken || "N/A"}</center></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

          </Box>

          {/* Snackbar */}
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <MuiAlert
              onClose={handleCloseSnackbar}
              severity={snackbarMessage.includes("successfully") ? "success" : "error"}
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </MuiAlert>
          </Snackbar>

      </Container> }
    </>
  );
}

export default BagwisProfitableAdmin;
