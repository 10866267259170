// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut   } from "firebase/auth";
import { getFirestore, query, collection, limit, getDocs } from "firebase/firestore"; // Import Firestore

// Firebase configuration (replace with your own Firebase config)
const firebaseConfig = {
  apiKey: "AIzaSyDnWWEyMqZgYSuMjsR-K0_Hrz61DHydneI",
  authDomain: "bagwisea.firebaseapp.com",
  projectId: "bagwisea",
  storageBucket: "bagwisea.appspot.com",
  messagingSenderId: "590983712360",
  appId: "1:590983712360:web:8275334385d651fbc3f5dd",
  measurementId: "G-YS6N17RC5Z"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);



export { auth, provider, signInWithPopup, db,signOut, query, collection, limit, getDocs  };
