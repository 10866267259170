// src/App.js
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { UserProvider } from './UserContext';
import Home from "./pages/Home";
import About from "./pages/About";
import SignIn from "./pages/SignIn";
import BagwisProfitableAdmin from "./pages/BagwisProfitableAdmin";

function App() {

  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/bagwisprofitableadmin" element={<BagwisProfitableAdmin />} />
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;
