// src/pages/Home.js
import React, { useState, useEffect } from "react";
import { CssBaseline, Container, Box, TextField, Button, Typography } from "@mui/material";
import { auth, provider, signInWithPopup, db  } from "../firebaseConfig";
import { useNavigate, useLocation  } from "react-router-dom";
import { doc, setDoc, getDoc, updateDoc, collection, query, limit, getDocs } from "firebase/firestore";
import GoogleIcon from '@mui/icons-material/Google';
import { useUser } from '../UserContext';
import * as CryptoJS from "crypto-js";



function generateUniqueToken() {
  return CryptoJS.lib.WordArray.random(32).toString();
}



const SignIn  = () => {

   const { setUser, user } = useUser(); // Get setUser from context
   const navigate = useNavigate();
  


   useEffect(() => {
        
        document.body.style.backgroundColor = 'rgba(0, 0, 0, 0.87)';
 
        if( typeof user !== 'undefined' &&  user !== null )
        { 
          if( user.authChecked === true && (typeof user.uid !== 'undefined')  )
          {
            navigate("/");
          }
        }

        

     
    }, [user, navigate]);


   const handleGoogleSignIn = async () => {
      try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        const userDocRef = doc(db, "users", user.uid);

        const userDoc = await getDoc(userDocRef);

        if (!userDoc.exists())
        {
          await setDoc(userDocRef, {
              uid: user.uid,
              name: user.displayName,
              email: user.email,
              profilePic: user.photoURL,
              createdAt: new Date(),
              lotsCredits: 5,
              startingLotSize: 0.01,
              maxPosition: 1,
              allowableSpread: 2,
              userToken: CryptoJS.lib.WordArray.random(16).toString(),
              tradingSessions: ["Asian", "London", "New York"]
            }, { merge: true });


          const adminSettingsCollection = collection(db, "adminSettings");
          const q = query(adminSettingsCollection, limit(1));

          const querySnapshot = await getDocs(q);

         if (!querySnapshot.empty) {
          // Get the first document
          const firstDoc = querySnapshot.docs[0];
          const adminid = firstDoc.id;
          const usersCount = firstDoc.data().usersCount || 0;

          if (adminid) {
            const adminDocRef = doc(db, "adminSettings", adminid);

            try {
              // Update usersCount atomically
              await updateDoc(adminDocRef, {
                usersCount: Number(usersCount) + 1,
              });
            
            } catch (error) {
              console.error("Error updating users count:", error);
            }
          }
        } else {
          console.log("No documents found in the adminSettings collection.");
        }

        }
        

        // Store user data globally
        setUser({
          uid: user.uid,
          name: user.displayName,
          email: user.email,
          profilePic: user.photoURL,
          authChecked:true
        });

        navigate("/");
      } catch (error) {
        console.error("Error signing in with Google:", error);
      }
    };

   return (
    <>
      <CssBaseline />
      <Container maxWidth="lg">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "0px" }}>
          <img src="/bagwisea.png" alt="Bagwis EA" style={{ maxWidth: "10%", maxHeight: "100%" }} />
          <h1 style={{marginTop: "10px",fontSize:"5rem",textAlign:"center",color:"#fff"}}>Create Automated Trading Profits with <span style={{ color: "#FF4500" }}>Bagwis EA</span></h1>
          <p style={{ fontSize: "1.5rem", textAlign: "center", color: "#fff" }}>
             Bagwis EA works around the clock to capture market opportunities, helping you earn steady profits with ease.
          </p>
          <Button
            variant="contained"
            color="default"
            onClick={handleGoogleSignIn}
            startIcon={<GoogleIcon />}
            sx={{
              backgroundColor: 'transparent', // Make button background transparent to blend with page
              border: '2px solid #FF4500', // Fire-red border color
              borderRadius: '20px',
              padding: '10px 20px',
              fontSize: '16px',
              display: 'flex',
              alignItems: 'center',
              color: '#fff', // Optional: set text color to match the border
              '&:hover': {
                backgroundColor: 'rgba(255, 69, 0, 0.1)', // Add a subtle hover effect with fire-red tint
              }
            }}
          >
            Sign in with Google
          </Button>
        </div>
        </Box>
      </Container>
    </>
  );
}

export default SignIn;
