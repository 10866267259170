// src/pages/Home.js
import React, { useState } from "react";
import { CssBaseline, Container, Box, TextField, Button, Typography } from "@mui/material";
import { auth } from "../firebaseConfig";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from "firebase/auth";

const About = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);
  const [isSigningUp, setIsSigningUp] = useState(false);

  // Handle login
  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user);
    } catch (error) {
      console.error("Error logging in:", error.message);
    }
  };

  // Handle signup
  const handleSignup = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user);
    } catch (error) {
      console.error("Error signing up:", error.message);
    }
  };

  // Handle logout
  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  };

   return (
    <>
      <CssBaseline />
      <Container maxWidth="sm">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            {isSigningUp ? "Sign Up" : "Login"}
          </Typography>

          {!user ? (
            <Box component="form" sx={{ mt: 3 }}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {isSigningUp ? (
                <Button fullWidth variant="contained" color="primary" onClick={handleSignup}>
                  Sign Up
                </Button>
              ) : (
                <Button fullWidth variant="contained" color="primary" onClick={handleLogin}>
                  Login
                </Button>
              )}
              <Button
                fullWidth
                variant="text"
                sx={{ mt: 2 }}
                onClick={() => setIsSigningUp(!isSigningUp)}
              >
                {isSigningUp ? "Already have an account? Login" : "Don't have an account? Sign Up"}
              </Button>
            </Box>
          ) : (
            <Box>
              <Typography variant="h6" gutterBottom>
                Welcome, {user.email}
              </Typography>
              <Button fullWidth variant="contained" color="secondary" onClick={handleLogout}>
                Logout
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
}

export default About;
