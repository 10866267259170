// src/UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, signOut } from './firebaseConfig';

// Create a context for user data
const UserContext = createContext();

// Custom hook to use the UserContext
export const useUser = () => {
  return useContext(UserContext);
};

// Provider component
export const UserProvider = ({ children }) => {
  const [user, setUser, ] = useState(null); // State to hold user information
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() =>{

        setUser({
          authChecked:false
        });

       const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {

        if (firebaseUser) {
          
          setUser({
              uid: firebaseUser.uid,
              name: firebaseUser.displayName,
              email: firebaseUser.email,
              profilePic: firebaseUser.photoURL,
              authChecked:true
            });
          
           
        }else{
          setUser({
            authChecked:true
          });
        } 

      });

      return () => unsubscribe();

  },[]);


  // Function to log out the user
  const logout = async () => {
    setUser(null); // Clear user state
    await signOut(auth);
    
  };



  return (
    <UserContext.Provider value={{ user, setUser, logout }}>
      {children}
    </UserContext.Provider>
  );
};
