// src/pages/Home.js
import React, { useEffect, useState, useRef, useMemo    } from "react";
import {  Dialog, DialogTitle, DialogContent, DialogActions, Grid,Accordion, AccordionSummary, AccordionDetails, Paper, Alert, Snackbar, LinearProgress, FormGroup, FormControlLabel, Checkbox, CssBaseline, Container, Box, TextField, Button, Typography, Avatar, Tooltip, IconButton, List, ListItem, ListItemText } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { auth, db, query, collection, limit, getDocs } from "../firebaseConfig";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { useUser } from '../UserContext';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { doc, getDoc, setDoc } from "firebase/firestore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from "@mui/system";
import Add from "@mui/icons-material/Add";
import Remove from "@mui/icons-material/Remove";
import axios from "axios";
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

const FireButton = styled(Button)(({ theme }) => ({
  margin: "20px",
  padding: 0,
  borderRadius: "10px",
  overflow: "hidden",
  transition: "0.3s",
  "&:hover": {
    border: "2px solid",
    borderImageSource: "linear-gradient(45deg, red, orange, yellow)",
    borderImageSlice: 1,
    boxShadow: "0 0 10px rgba(255, 69, 0, 0.8), 0 0 20px rgba(255, 140, 0, 0.6)",
    transform: "scale(1.05)",
  },
}));




const Home  = () => {

    const { user, logout } = useUser();
    const navigate = useNavigate(); // Initialize navigate
    const [loading, setLoading] = useState(true); // Loading state for user data
    const [userData, setUserData] = useState(null);
    const userDocumentFetched = useRef(false);
    let userSettings = {};
    const [startingLotSize, setStartingLotSize] = useState("");
    const [downloadUrl, setDownloadUrl] = useState("");
    const [price, setPrice] = useState("");
    const [downloadVersion, setDownloadVersion] = useState("");
    const [allowableSpread, setAllowableSpread] = useState("");
    const [maxPosition, setMaxPosition] = useState("");
   
    const tradingSessions = ["Asian", "London", "New York"];
    const [selectedSessions, setSelectedSessions] = useState("");

    const [open, setOpen] = useState(false);
    const [openBuy, setOpenBuy] = useState(false);
    const [opengcash, setopengcash] = useState(false);
    const [openBuyCalculator, setOpenBuyCalculator] = useState(false);
    const [lotSize, setLotSize] = useState(1);
    let totalLotSize = 0;
    let checkout = 0;

    const [usd, setUsd] = useState(1); // Default USD value
    const [php, setPhp] = useState(0); // Converted PHP value
    const [exchangeRate, setExchangeRate] = useState(null);
    const [paypalLoaded, setPaypalLoaded] = useState(false);

    const PAYPAL = {
       sandbox:
         "AVEYcm1KHnlX3M8PmatfyQOHyyTH5SxcveT91uGDfAwGX7iKFHSrgWuQG5wCrHJ_SWilj3dxngw4p8JQ",
       production:
         "AQYtTxFdjYlk3xWawpXUVifcuxF45Tt_AFSP6bPvEvWHOhJ6N32DLiFURb8jMHSpH3sckzdRYKdTgQjK",
       currency: "USD"
     };

    const handleChangeSession = (session: string) => {
      setSelectedSessions((prev) =>
        prev.includes(session) ? prev.filter((s) => s !== session) : [...prev, session]
      );
    };


     useEffect(() => {
        if (openBuy) {
          setPaypalLoaded(true); // Load script when dialog is open
        }
      }, [openBuy]);

    useEffect(() => {
         setLoading(false);
    }, [user]);

     useEffect(() => {
        // Fetch exchange rate on component mount
        const fetchExchangeRate = async () => {
          try {
            const response = await axios.get(
              "https://open.er-api.com/v6/latest/USD" // Example API
            );
            setExchangeRate(response.data.rates.PHP); // Set PHP rate
          } catch (error) {
            console.error("Error fetching exchange rate:", error);
          }
        };
        fetchExchangeRate();
    }, []);
  
    
    useEffect(() => {
        
        document.body.style.backgroundColor = '';

        if( typeof user !== 'undefined' &&  user !== null )
        { 
            if( user.authChecked === true && typeof user.uid === 'undefined' ||  user.uid === null  )
            {
                   navigate("/signIn");
            }else if( user.authChecked === true && typeof user.uid !== 'undefined'  )
            {

                if (!userDocumentFetched.current)
                {
                      const fetchUserDocument = async () => {

                      const userDocRef = doc(db, "users", user.uid);
                      const userDocSnap = await getDoc(userDocRef);

                      if (userDocSnap.exists())
                      {

                         setUserData(userDocSnap.data());
                         userDocumentFetched.current = true; 

                         setStartingLotSize(userDocSnap.data().startingLotSize); // Default value if not set
                         setAllowableSpread(userDocSnap.data().allowableSpread); // Default value if not set
                         setMaxPosition(userDocSnap.data().maxPosition ); // Default value if not set
                         setSelectedSessions(userDocSnap.data().tradingSessions)
                      }
                      else
                      {
                        console.log("No such document!");
                      }

                    };

                    const fetchAdminSettings = async () =>{

                       const adminSettingsCollection = collection(db, "adminSettings");
                       const q = query(adminSettingsCollection, limit(1));

                       const querySnapshot = await getDocs(q);

                        if (!querySnapshot.empty) {
                          // Get the first (and only) document
                          querySnapshot.forEach((doc) => {
                            setDownloadUrl(doc.data().uploadUrl)
                            setDownloadVersion(doc.data().uploadVersion)
                            setPrice(doc.data().price)
                          });
                        } else {
                          console.log("No documents found in the adminSettings collection.");
                        }

                    }

                    fetchUserDocument();
                    fetchAdminSettings();

                }
                  
            }
        }

    }, [loading, user, navigate]);
    
    const handleSubmit = async (e) => {
        setLoading(true);
        setOpen(true);
        e.preventDefault();
        
        try {

            const userDocRef = doc(db, "users", user.uid); 

            await setDoc(userDocRef, {
              startingLotSize: startingLotSize,
              allowableSpread: allowableSpread,
              maxPosition: maxPosition,
              tradingSessions: selectedSessions
            }, { merge: true });

            setOpen(false);
            setLoading(false);

           
        }catch (error) {
            console.error("Error updating user settings:", error);
            setLoading(false);
            setOpen(false);
        }

       
      };

    const handleLogout = async () => {
       setLoading(true);
	    try {
	      await logout(); // Clear user from context and Firebase
	      navigate("/signin"); // Redirect to sign-in page after logging out
	    } catch (error) {
	      console.error("Error logging out:", error);
         setLoading(false);
	    }
	   };


    const increment = () => {
      setLotSize((prev) => prev + 1);
    };

    const decrement = () => {
      setLotSize((prev) => (prev > 1 ? prev - 1 : 1)); // Prevent value from going below 1
    };

    const buyCredits = async () => {
        setOpenBuyCalculator(true);
     };

    const buyCreditsClose = async () => {
        setOpenBuyCalculator(false);
     };

    const opengcashClose = async () => {
         setopengcash(false);;
     };

     const openBuyClose = async () => {
         setOpenBuy(false);;
     };

    
     const openBuyCalculatorClose = async () => {
        setOpenBuyCalculator(false);
     };

     const handlePaymentSelection = (method) =>
     {
      
        if(method=="gcash")
        {
          setOpenBuy(false);
        }else if(method=="paypal")
        {

        }
     
        
     };

      totalLotSize = lotSize + ( lotSize * 0.1 );
      checkout = (lotSize * price ) * exchangeRate;
   
   return (
    <>
      <CssBaseline />
     
      <Container maxWidth="md">
           { loading && <LinearProgress
                          sx={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: 8,
                            borderRadius: 4,
                            backgroundColor: "rgba(255, 69, 0, 0.2)", // Light fire red for the track
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: "#FF4500", // Fire red color for the progress bar
                            },
                          }}
                        />
          }
          <h1 style={{ fontSize: "3rem", textAlign: "center", margin:"10px" }}>Profile</h1>
           <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2,
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: 'rgba(0, 0, 0, 0.87)'
              }}
            >
              <Avatar 
                alt={ (userData ? userData.name : "" ) } 
                src={ ( userData ?  userData.profilePic : "") } 
                sx={{ width: 80, height: 80, marginBottom: 2,  border: '3px solid #FF4500' }} 
              />
             
              <Typography sx={{color:"#fff"}} variant="h6" component="div">
                Name: { userData ? userData.name : ""}
              </Typography>

              <Typography sx={{color:"#fff"}} variant="body1" component="div">
                Email: { userData ? userData.email : ""}
              </Typography>

              <Typography sx={{color:"#fff"}} variant="body1" component="div">
                Login Token: { userData ? userData.userToken : ""}
              </Typography>

              <Button variant="contained" color="secondary" onClick={handleLogout} sx={{ width:"20%", color: '#fff', // Optional: set text color to match the border
              '&:hover': {
                backgroundColor: 'rgba(255, 69, 0, 0.1)', // Add a subtle hover effect with fire-red tint
              }, border: '2px solid #FF4500', backgroundColor: 'transparent', marginTop: 2 }}>
                Logout
              </Button>
            </Box>

            <h1 style={{ fontSize: "3rem", textAlign: "center", margin:"10px" }}>Trading Credits</h1>
            <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2,
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: 'rgba(0, 0, 0, 0.87)'
              }}
            >
             
              <Typography variant="h1" component="div"  sx={{ color: "#FF4500" }}>
                 { userData ? Number(userData.lotsCredits).toFixed(2) : 0.00 }
              </Typography>

              <Box display="flex" alignItems="center" mt={1}>
                <Typography variant="body1" sx={{ marginRight: 1, color:"#fff" }}>
                  What is this?
                </Typography>
                <Tooltip title="Your lot size credits are the points you can use for Bagwis EA to make trades. These credits indicate how much you can trade on the platform. When Bagwis EA uses some of your lot size credits for a trade, that amount will be deducted from your total credits. It's important to ensure you have enough credits so you can participate in trading smoothly." arrow>
                  <IconButton sx={{ padding: 0, color:"#fff" }} size="small">
                    <InfoIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Box>

              <Button variant="contained" color="secondary" onClick={buyCredits} sx={{ width:"20%", color: '#fff', // Optional: set text color to match the border
              '&:hover': {
                backgroundColor: 'rgba(255, 69, 0, 0.1)', // Add a subtle hover effect with fire-red tint
              }, border: '2px solid #FF4500', backgroundColor: 'transparent', marginTop: 2 }}>
                Buy Credits
              </Button>

              
            </Box>

            <h1 style={{ fontSize: "3rem", textAlign: "center", margin:"10px" }}>Settings</h1>
            <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2,
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: 'rgba(0, 0, 0, 0.87)'
              }}
            >
               <form onSubmit={handleSubmit}>
                
                <TextField
                  label="Starting Lot Size"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={startingLotSize}
                  onChange={(e) => setStartingLotSize(e.target.value)}
                  required
                  InputLabelProps={{
                    style: { color: "#fff" }, // Label color white
                  }}
                  InputProps={{
                    style: { color: "#fff" }, // Text color white
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#fff", // Default border color white
                      },
                      "&:hover fieldset": {
                        borderColor: "#fff", // Hover border color white
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#fff", // Focused border color white
                      },
                    },
                  }}
                />

                  <TextField
                    label="Allowable Spread"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={allowableSpread}
                    onChange={(e) => setAllowableSpread(e.target.value)}
                    required
                    InputLabelProps={{
                      style: { color: "#fff" }, // Label color white
                    }}
                    InputProps={{
                      style: { color: "#fff" }, // Text color white
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#fff", // Default border color white
                        },
                        "&:hover fieldset": {
                          borderColor: "#fff", // Hover border color white
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#fff", // Focused border color white
                        },
                      },
                    }}
                  />

                   <TextField
                    label="Max Position"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={maxPosition}
                    onChange={(e) => setMaxPosition(e.target.value)}
                    required
                    InputLabelProps={{
                      style: { color: "#fff" }, // Label color white
                    }}
                    InputProps={{
                      style: { color: "#fff" }, // Text color white
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#fff", // Default border color white
                        },
                        "&:hover fieldset": {
                          borderColor: "#fff", // Hover border color white
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#fff", // Focused border color white
                        },
                      },
                    }}
                  />

                  <Typography sx={{color:"#fff"}} variant="p" gutterBottom>
                    Select Trading Sessions
                  </Typography>

                  <FormGroup>
                    {tradingSessions.map((session) => (
                      <FormControlLabel
                        key={session}
                        control={
                          <Checkbox
                            checked={selectedSessions.includes(session)}
                            onChange={() => handleChangeSession(session)}
                            sx={{
                              color: "#FF4500", // Fire-red color for unchecked state
                              "&.Mui-checked": {
                                color: "#FF4500", // Fire-red color for checked state
                              },
                            }}
                          />
                        }
                        label={session}
                        sx={{
                          color: "#fff", // White color for the label text
                        }}
                      />
                    ))}
                  </FormGroup>
                  

                  <center>
                    <Button
                      variant="contained"
                      color="secondary"
                      type="submit"
                      sx={{ width:"20%", color: '#fff', // Optional: set text color to match the border
              '&:hover': {
                backgroundColor: 'rgba(255, 69, 0, 0.1)', // Add a subtle hover effect with fire-red tint
              }, border: '2px solid #FF4500', backgroundColor: 'transparent', marginTop: 2 }}
                    >
                      Save
                    </Button>
                  </center>

               </form>

               <Snackbar
                open={open}
                autoHideDuration={6000} // Auto hide after 6 seconds
                onClose={()=>{ setOpen(false) }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position at top center
              >
                <Alert onClose={ ()=>{setOpen(false)}  } severity="success" sx={{ width: '100%' }}>
                  Save successful.
                </Alert>
              </Snackbar>

            </Box>

             <h1 style={{ fontSize: "3rem", textAlign: "center", margin:"10px" }}>Downloads</h1>
             <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2,
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: 'rgba(0, 0, 0, 0.87)'
              }}
            >
              <Typography sx={{color:"#fff"}} variant="h3" component="div">
                Bagwis EA {downloadVersion}
              </Typography>

              <Typography sx={{color:"#fff"}} variant="p" component="div">
                Bagwis EA is an advanced forex trading bot designed for the MetaTrader 5 (MT5) platform. It leverages a strategy to optimize trading opportunities across the Asian, London, and New York sessions. Compatible with all major currency pairs, Bagwis EA is versatile and performs well across various time frames, though the 1-minute time frame is recommended for optimal results. Its precision and adaptability make it an ideal choice for both novice and experienced traders.
              </Typography>

              <Button
                variant="contained"
                color="secondary"
                href={downloadUrl} // Set the URL for the file to be downloaded
                download // Prompt the browser to download the file
                sx={{ width:"20%", color: '#fff', // Optional: set text color to match the border
              '&:hover': {
                backgroundColor: 'rgba(255, 69, 0, 0.1)', // Add a subtle hover effect with fire-red tint
              }, border: '2px solid #FF4500', backgroundColor: 'transparent', marginTop: 2 }}
              >
                Download
              </Button>

              <hr style={{ borderColor: '#fff', borderWidth: 1, margin: '20px 0', width:'100%' }} /> 

              
               <Accordion sx={{ backgroundColor: '#333', color: 'white' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                     <Typography sx={{color:"#fff"}} variant="h5" gutterBottom>
                       Risk Disclaimer for Using Bagwis Expert Advisor (EA)
                    </Typography>
                   </AccordionSummary>
                   <AccordionDetails>
                        <List>
                            <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                secondary={
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `Trading in the forex market involves significant risk, and the use of an Expert Advisor (EA) does not guarantee profits. While EAs are designed to execute trades based on predefined algorithms, they are subject to market volatility, technical malfunctions, and changes in market conditions that can lead to losses. Past performance of an EA is not indicative of future results, and there is no assurance that the EA will perform as expected under live trading conditions. <br/><br/>
                                      By using an EA, you acknowledge and accept the risks associated with automated trading, including the potential loss of your entire investment. Trading with an EA is done at your own discretion and responsibility. <br/><br/> <div style='text-align:center;'>So....</div> <h1 style='color:#FF4500;text-align:center;font-size:48px;'>TAYOR <br/><span style='font-size:18px;'>( Take Your Own Risk)</span></h1> It is your responsibility to conduct thorough backtesting, forward testing, and risk management to align the EA's strategy with your trading goals and risk tolerance.
                                      The developer of this EA is not responsible for any financial losses incurred while using the software. You agree that all trading decisions made using this EA are entirely at your own risk, and <b style='color:#FF4500'>the developer bears no liability for any unfavorable outcomes.</b>
                                      <br/><br/>
                                      Always trade responsibly and <b style='color:#FF4500'>NEVER RISK MORE THAN YOU CAN AFFORD TO LOSE</b>.
                                      `
                                    }}
                                  />
                                }

                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>

                           

                            


                        </List>
                   </AccordionDetails>
              </Accordion>

              <Accordion sx={{ backgroundColor: '#333', color: 'white' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                     <Typography sx={{color:"#fff"}} variant="h5" gutterBottom>
                        How to Install Bagwis Expert Advisor (EA) in MetaTrader 5 (MT5)
                    </Typography>
                   </AccordionSummary>
                   <AccordionDetails>
                        <List>
                            <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                primary="1. Download Bagwis EA"
                                secondary="Ensure you have the .ex5 file for the EA saved on your computer."
                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>

                            <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                primary="2. Open MetaTrader 5"
                                secondary="Launch your MetaTrader 5 platform."
                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>

                            <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                primary="3. Open the Data Folder"
                                
                                secondary={
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `In MT5, go to File &gt; Open Data Folder. <br />
                                        <img src="s1.png" alt="Image description" style="width: 100%; margin-top: 10px;" />
                                        `
                                    }}
                                  />
                                }

                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>


                            <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                primary="4. Locate the Experts Folder"
                                secondary="Navigate to MQL5 -> Experts in the Data Folder."
                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>


                            <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                primary="5. Copy Bagwis EA File"
                                secondary="Copy the downloaded .ex5 file into the Experts folder."
                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>

                            <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                primary="6. Restart MetaTrader 5"
                                secondary="Close MT5 and reopen it to ensure the EA is recognized."
                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>

                            <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                primary="7. Enable Auto-Trading"
                                secondary={
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `Click the AutoTrading button in MT5's top toolbar to enable automated trading. <br />
                                        <img src="s2.png" alt="" style="width: 100%; margin-top: 10px;" />
                                        `
                                    }}
                                  />
                                }

                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>

                            <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                primary="8. Attach Bagwis EA to a Chart"

                                secondary={
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `Drag and drop the EA from the Navigator panel onto a chart. <br />
                                        <img src="s3.png" alt="" style="width: 100%; margin-top: 10px;" />
                                        `
                                    }}
                                  />
                                }

                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>

                            <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                primary="9. Allow Bagwis EA Api url in MT5 Web request."
                                
                                secondary={

                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `Go to Tools -> Options in the toolbar menu. <br />
                                        <img src="s4.png" alt="" style="width: 100%; margin-top: 10px;" /> <br/>

                                        Copy and paste this link to Web Request url. <br />
                                        <span style='color:#FF4500;'>https://bagwisapi-msu5b4wuoa-uc.a.run.app/</span> <br/>
                                        <img src="s5.png" alt="" style="width: 100%; margin-top: 10px;" /> <br/>
                                        `
                                    }}
                                  />

                                }

                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>


                            <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                primary="10. Login to your Bagwis EA account to copy your login token."
                                
                                secondary={

                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `Go to <span style='color:#FF4500;'>bagwisea.com</span> then sign in your account.<br />
                                        <img src="s6.png" alt="" style="width: 100%; margin-top: 10px;" /> <br/>

                                        Copy your login token. <br/>

                                        <img src="s7.png" alt="" style="width: 100%; margin-top: 10px;" /> <br/>
                                        `
                                    }}
                                  />

                                }

                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>

                             <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                primary="11. Paste your login token in the Bagwis EA panel."
                                
                                secondary={

                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `Go back to MT5. Paste the login token in the EA panel and click start.<br />
                                        <img src="s8.png" alt="" style="width: 100%; margin-top: 10px;" /> <br/>

                                        `
                                    }}
                                  />

                                }

                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>

                             <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                primary="12. Run Bagwis EA."
                                
                                secondary={
                                  <>
                                    Run Bagwis EA and enjoy your profits.
                                  </>
                                }

                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>



                        </List>
                   </AccordionDetails>
              </Accordion>


              <Accordion sx={{ backgroundColor: '#333', color: 'white' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                     <Typography sx={{color:"#fff"}} variant="h5" gutterBottom>
                       Backtesting Bagwis EA in MetaTrader 5 (MT5)
                    </Typography>
                   </AccordionSummary>
                   <AccordionDetails>
                        <List>

                            <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                primary="What is MT5 EA Backtesting?"
                                secondary={
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `Backtesting in MetaTrader 5 (MT5) is the process of testing an Expert Advisor (EA) against historical market data to evaluate its performance. It involves simulating trades using past price movements to see how well the EA would have performed under similar conditions. Backtesting allows traders to analyze an EA's strategy, identify strengths and weaknesses, and make data-driven decisions for optimization. <br/><br/> In MT5, backtesting is conducted in the Strategy Tester, a built-in tool that enables users to test EAs with various settings, time frames, and market conditions. It supports different types of modeling, including tick-by-tick simulation, which provides high accuracy in trade execution and price behavior analysis.
                                        `
                                    }}
                                  />
                                }

                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>

                           

                            <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                primary="1. Open MetaTrader 5"
                                secondary="Launch your MetaTrader 5 platform."
                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>

                            <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                primary="2. Select Strategy Tester"
                                
                                secondary={
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `Find BagwisEA in the Navigator panel. Right click the EA and click Test. <br />
                                        <img src="s9.png" alt="" style="width: 100%; margin-top: 10px;" />
                                        `
                                    }}
                                  />
                                }

                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>


                            <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                primary="2. Set Strategy Tester Settings"
                                
                                secondary={
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `In the Settings Tab, follow the settings in the screenshot below. <br /> 
                                      <ul>
                                        <li><b>Expect - </b> Select BagwisEA.</li>
                                        <li><b>Symbol - </b> Select the symbol you want to backtest BagwisEA.</li>
                                        <li><b>Timeframe - </b> Next to the symbol input, select M1 as your timeframe. You can also select different timeframes.</li>
                                        <li><b>Date - </b> Select the date range you want backtest BagwisEA.</li>
                                        <li><b>Forward - </b> Select No as default.</li>
                                        <li><b>Delays - </b> Select "Zero latency, ideal execution".</li>
                                        <li><b>Modeling - </b> Select "Every tick based on real ticks".</li>
                                        <li><b>Deposit - </b> Input your deposit amount. It is important to input your actual capital for better simulation. Select USD as your currency. You can also select other currency.</li>
                                        <li><b>Leverage - </b> Select your actual leverage you use in your live account for better simulation.</li>
                                        <li><b>Optimization - </b> Select disabled as default.</li>
                                        <li><b>Visual Mode - </b> Select Uncheck for faster viewing of the results. Select Check if you want to visualize it on the actual chart.</li>
                                      </ul>
                                        <img src="s10.png" alt="" style="width: 100%; margin-top: 10px;" />
                                        `
                                    }}
                                  />
                                }

                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>


                            <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                primary="2. Set Strategy Input"
                                
                                secondary={
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `In the Inputs Tab, follow the settings in the screenshot below. <br /> 
                                      <ul>
                                        <li><b>BotTest - </b> Input 1 to enable test.</li>
                                        <li><b>Lotsize - </b> Input the lot size you want to test.</li>
                                        <li><b>AllowableSpread - </b> Input the allowable spread you want to test.</li>
                                        <li><b>Maxentries - </b> Input the number of positions you want to test.</li>
                                        <li><b>TradingSessions - </b> Input the trading session you want to test. Acceptable values will be Asian, London and New York. You can input one or all delimited by space or comma.</li>
                                      </ul>
                                        <img src="s11.png" alt="" style="width: 100%; margin-top: 10px;" />
                                        `
                                    }}
                                  />
                                }

                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>

                            <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                primary="2. Start testing"
                                
                                secondary={
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `Click Start to start testing. <br /> 
                                   
                                        <img src="s12.png" alt="" style="width: 100%; margin-top: 10px;" />
                                        `
                                    }}
                                  />
                                }

                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>

                             <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                primary="2. Graph"
                                
                                secondary={
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `Switch to Graph tab to view actual test of your balance and equity. <br /> 
                                   
                                        <img src="s13.png" alt="" style="width: 100%; margin-top: 10px;" />
                                        `
                                    }}
                                  />
                                }

                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>

                            <ListItem sx={{ paddingLeft: 0 }}>
                              <ListItemText
                                primary="2. Backtest"
                                
                                secondary={
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `Switch to Backtest tab to view detailed data of your test. <br /> 
                                   
                                        <img src="s14.png" alt="" style="width: 100%; margin-top: 10px;" />
                                        `
                                    }}
                                  />
                                }

                                sx={{
                                  color: '#FF4500', // This styles the primary text
                                  '& .MuiListItemText-secondary': { color: 'white' } // This specifically targets and styles the secondary text
                                }}
                              />
                            </ListItem>



                        </List>
                   </AccordionDetails>
              </Accordion>
              
            </Box>

            <Box mt={20} />
        
        <Dialog maxWidth="sm" fullWidth open={openBuyCalculator} onClose={openBuyCalculatorClose}>
            <DialogTitle sx={{ textAlign: 'center' }}>Buy Trading Lot Size</DialogTitle>
            <DialogContent>
                  <form>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 20 }}>
                      <IconButton onClick={decrement} color="primary">
                        <Remove />
                      </IconButton>
                      <TextField
                        value={lotSize}
                        onChange={(e) => setLotSize(Number(e.target.value))}
                        type="number"
                        inputProps={{ min: 1 }}
                        style={{ width: 100, textAlign: "center" }}
                      />
                      <IconButton onClick={increment} color="primary">
                        <Add />
                      </IconButton>
                    </div>
                </form>
                <br/>
                <Typography sx={{ textAlign: "center", marginBottom: 2 }}>
                  You are buying <strong>{lotSize}</strong> lot Credits.
                </Typography>
                <Typography sx={{ textAlign: "center", marginBottom: 2 }}>
                  🎉 Enjoy a <strong>10% bonus</strong>! You will receive a total of <strong>{totalLotSize.toFixed(2)}</strong> lot credits.
                </Typography>

                <h2 style={{ textAlign: "center", marginBottom: 2 }}>
                  You will pay ${ (lotSize * price).toFixed(2) } <br/>
                </h2>
                <Typography sx={{ textAlign: "center", marginBottom: 2, fontSize:14, color:"red" }}>Price may change overtime without prior notice.</Typography>

                <br/>

                <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <Button
                      onClick={()=>{
                        setOpenBuyCalculator(false);
                        setOpenBuy(true);
                      }}
                        variant="contained"
                        color="success"
                      >
                      Proceed to Checkout
                    </Button>
                </Box>

                

            </DialogContent>

        </Dialog>

        <Dialog maxWidth="sm" fullWidth open={openBuy} onClose={openBuyClose}>
            <DialogTitle sx={{ textAlign: 'center' }}>Select Payment Method</DialogTitle>
            <DialogContent>
                 <Grid container spacing={2} justifyContent="center">
                    
                    <Grid item>
                    <FireButton  onClick={() => {
                      setOpenBuy(false);
                      setopengcash(true);
                    }}>
                      <img
                        src="gcash.png"
                        style={{ height: "auto", width: 200, margin: 10 }}
                      />
                    </FireButton>
                  </Grid>
                  <Grid item>
                     {openBuy && paypalLoaded && (
                        <div style={{ height: "auto", width: 200, marginTop: 40 }}>
                          <PayPalScriptProvider options={{ "client-id": PAYPAL.production  }}>
                            
                            <PayPalButtons
                              style={{ layout: "vertical" }}
                              createOrder={(data, actions) => {
                                return actions.order.create({
                                  purchase_units: [
                                    {
                                      amount: {
                                        value: lotSize * price, // The amount the user has to pay
                                      },
                                      description: "Bagwis EA Trading Lots"
                                    },
                                  ],
                                  application_context:{
                                    brand_name: "Bagwis EA",
                                    shipping_preference: "NO_SHIPPING"
                                  }

                                });
                              }}
                              onError={
                                (err) => {

                                }
                              }

                              onCancel={
                                (err) => {
                                  
                                }
                              }

                              onApprove={async (data, actions) => {
                                return actions.order.capture().then(async (details) => {

                                  try {

                                      const userDocRef = doc(db, "users", userData.uid); 

                                      await setDoc(userDocRef, {
                                        lotsCredits : userData.lotsCredits + totalLotSize
                                      }, { merge: true });

                                      window.location.reload()
                                      setLoading(false);
                                  }catch (error) {
                                      console.error("Error updating user settings:", error);
                                      setLoading(false);
                                  }


                                });
                              }}
                            />

                          </PayPalScriptProvider>
                        </div>
                      )}
                  </Grid>
                 </Grid>
            </DialogContent>
           
        </Dialog>


        <Dialog maxWidth="sm" fullWidth open={opengcash} onClose={opengcashClose}>
            <DialogTitle sx={{ textAlign: 'center' }}>Gcash</DialogTitle>
            <DialogContent>

                <Grid container spacing={2} justifyContent="center">
                    <Grid item>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <img
                            src="gcashqr2.png"
                            alt="Gcash QR Code"
                            style={{ width: "70%", height: "auto", margin: "10px 0" }}
                          />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Typography sx={{ textAlign: "center", marginBottom: 2 }}>
                        Scan the QR code using your GCash app, then complete the payment. After making the payment, copy and paste the content below, attach your proof of payment, and send it to <b>bagwiseasandbox@gmail.com</b>. We will update your trading lot balance within 24 hours.</Typography>
                       
                       <hr/>
                        <Typography sx={{ textAlign: "center", marginBottom: 2 }}>

                          <b>Account Email:</b>  { userData ? userData.email : ""} <br/>
                          <b>Trading Lot Size:</b> {totalLotSize} <br/>
                          <b>Total Amount:</b> Php{Number(checkout).toFixed(2) } <br/>
                        </Typography>
                    </Grid>
                </Grid>

                 
            </DialogContent>
           
        </Dialog>
      </Container>
    </>
  );
}

export default Home;
